$breakpoints: (
    xs: 0px,
    sm: 600px,
    md: 960px,
    lg: 1280px,
    xl: 1920px
);

@mixin responsive($breakpoint) {
    @if $breakpoint != xl {
        @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
            @content
        }    
    }

    @if $breakpoint == xl {
        @media only screen and (min-width: map-get($breakpoints, xl)) {
            @content
        }    
    }
}