@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import 'variables';
@import 'query';

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow-y: auto;
    color: $primary-text;
    background: $background-primary;
}

#__react-alert__>div>div>div {
    text-transform: initial !important;
    background-color: $primary-color  !important;
    color: #f8f5f5 !important;
}

.row {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 15px;
}

p {
    font-size: 1.1rem;
    line-height: 32px;
}

a {
    text-decoration: none;
}

.btn {
    padding: 15px 30px;
    background: $primary-color;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;

    &.disabled {
        background: #ccc;
    }
}

.helperText {
    font-size: 0.90rem;
    color: #d34c4c;
}

.grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    box-sizing: border-box;

    &>div {
        padding: 0px 8px;
    }
}

.grid-100 {
    width: 100%;
    box-sizing: border-box;
}

.grid-50 {
    width: 50%;
    box-sizing: border-box;
}

.grid-33 {
    width: 33.33%;
    box-sizing: border-box;
}

.body {
    position: relative;

    .page-loader {
        background: rgba(205, 205, 205, 0.7);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .loader {
            display: flex;
            flex-direction: column;
            text-align: center;

            margin-top: 25%;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 5px;
            padding: 8px;

            img {
                width: 100px;
            }
        }
    }
}

.wrapper {
    width: 100%;
    min-height: 100%;
    margin-bottom: -250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    .head {
        background: $background-paper;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        z-index: 50;
        padding: 0px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 1200px;
            padding: 10px;
            position: relative;

            .logo {
                img {
                    width: 100%;
                    max-width: 108px;
                }
            }

            .desktop {
                display: block;

                @include responsive(md) {
                    display: none;
                }
            }

            .mobile {
                display: none;
                width: 80px;
                position: relative;

                @include responsive(md) {
                    display: block;
                }

                .material-symbols-outlined {
                    color: $primary-color;
                    font-size: 45px;
                    font-weight: 500;
                }
            }

            .menu-container {
                width: 100%;
                background: #fff;
                position: absolute;
                right: 0;
                top: 100%;
                border-top: 1px solid $primary-color;

                .menu {
                    a {
                        display: block;
                        padding: 18px 25px;

                        &.active {
                            border: 0;
                        }
                    }
                }
            }

            .menu {
                a {
                    display: inline-block;
                    text-decoration: none;
                    color: $primary-text;
                    padding: 8px 20px;
                    font-size: 1.2rem;
                    font-weight: 500;
                    cursor: pointer;

                    &.active {
                        border-bottom: 3px solid $primary-color;
                    }
                }
            }

            .category-list {
                background: #fff;
                position: absolute;
                right: 120px;
                top: 100%;

                @include responsive(md) {
                    width: 100%;
                    right: unset;
                    border-top: 1px solid $primary-color;
                }

                .category {
                    color: $primary-text;
                    width: 100%;
                    display: block;
                    padding: 15px 25px;
                    box-sizing: border-box;

                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                    }
                }
            }
        }
    }

    .notice {
        background: #f44336;
        color: #fff;
        width: 100%;
        padding: 16px 0px;

        h3 {
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
            padding-left: 10px;
        }

        p {
            font-size: 0.90rem;
            font-weight: 300;
            padding-left: 10px;
            line-height: 36px;
        }
    }

    .layout {
        width: 100%;
        margin-top: 132px;

        .banner {
            position: relative;
            width: 100%;
            height: 500px;
            padding: 10px;
            background-image: url("../assets/banner.jpg");
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                max-width: 200px;
            }

            h1 {
                font-size: 4rem;
                color: #8291c9;
                text-shadow: 0 0 #efe8e8;
            }
        }

        .product-list {
            display: flex;
            flex-direction: column;

            & .product {
                width: 80%;
                display: flex;
                margin-bottom: 20px;
                background: $background-paper;
                box-shadow: 0 0.0625rem 0.125rem 0 rgb(0 0 0 / 10%);

                @include responsive(md) {
                    width: 95%;
                }

                .image {
                    padding: 20px;

                    img {
                        width: 100%;
                        max-width: 200px;
                    }
                }

                .details {
                    padding: 20px;
                    width: 100%;

                    h3 {
                        font-size: 1.5rem;
                        font-weight: 400;
                        padding: 10px 0px;
                    }

                    h4 {
                        font-size: 2rem;
                        font-weight: 500;
                        color: $primary-color;
                    }

                    .p {
                        font-size: 1rem;
                        line-height: 24px;
                        padding: 8px 0;
                        text-align: justify;
                    }

                    .btn-wrapper {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: end;

                        .form-control {
                            padding: 11px;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            box-sizing: border-box;
                            font-size: 15px;
                            color: $primary-text;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .featured {
            width: 100%;
            min-height: 200px;
            padding: 56px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                font-size: 2rem;
                text-align: center;
                width: 100%;
                margin-bottom: 50px;
            }
        }

        .products {
            width: 100%;
            min-height: 200px;
            padding: 56px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #ccc;

            h2 {
                font-size: 2rem;
                text-align: center;
                width: 100%;
                margin-bottom: 50px;
            }
        }

        .about {
            width: 100%;
            min-height: 450px;
            padding: 46px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            background-image: url("../assets/about.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;

            .block {
                background-color: rgba($color: $primary-color, $alpha: 0.7);
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
            }

            h2 {
                font-size: 2rem;
                text-align: center;
                width: 100%;
                color: $background-paper;
            }

            .row {
                display: flex;
                flex-direction: column;
                text-align: center;
                z-index: 11;

                p {
                    padding: 0px 240px;
                    color: $background-paper;
                    text-align: justify;

                    @include responsive(md) {
                        padding: 0px 8px;
                    }
                }

                .features {
                    display: flex;
                    align-items: stretch;
                    justify-content: center;
                    margin-top: 50px;

                    @include responsive(md) {
                        flex-direction: column;
                    }

                    .col {
                        flex: 1;
                        padding: 10px 30px;

                        h3 {
                            color: $background-paper;
                            padding-bottom: 10px;
                            border-bottom: 1px dotted $background-paper;
                        }

                        p {
                            padding: 10px;
                            font-size: 16px;
                        }

                        .material-symbols-outlined {
                            color: $background-paper;
                            font-size: 90px;
                        }
                    }
                }
            }
        }

        .contact {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 400px;
            padding: 20px 0px;

            .row {
                @include responsive(md) {
                    flex-direction: column;
                    align-items: stretch;
                }
            }

            .col {
                flex: 1;
                padding: 20px;

                &.contact-form {
                    padding: 50px 0px 50px 300px;

                    @include responsive(md) {
                        padding: 12px;
                    }

                    .notify-message {
                        padding: 8px;
                        font-size: 1rem;
                        line-height: 24px;
                        margin-bottom: 11px;
                    }

                    .grid {
                        flex-direction: column;
                        box-sizing: border-box;

                        .form-group {
                            margin-bottom: 10px;
                            display: block;

                            label.form-label {
                                display: block;
                                font-size: 16px;
                                font-weight: 400;
                                margin-bottom: 5px;
                            }

                            .form-control {
                                width: 100%;
                                padding: 10px 8px;
                                border: 1px solid #ccc;
                                border-radius: 4px;
                                box-sizing: border-box;
                                font-size: 18px;
                                color: $primary-text;
                            }

                            &.btn-wrapper {
                                display: flex;
                                align-items: center;
                                justify-content: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .payment {
        margin-top: 85px;
        margin-bottom: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .row {
            display: flex;
            flex-direction: column;

            h2 {
                margin-top: 50px;
            }

            p {
                text-align: center;
            }
        }

        .checkout-form {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-top: 20px;
            position: relative;

            @include responsive(md) {
                flex-direction: column;
            }

            @include responsive(md) {
                align-items: stretch;
            }

            &>div {
                margin: 16px;

                h3 {
                    font-size: 1.2rem;
                    font-weight: 400;
                    margin-bottom: 20px;
                }
            }

            .form {
                flex: 2;

                .group {
                    background: $background-paper;
                    padding: 20px;
                    margin-bottom: 20px;
                }

                .form-group {
                    margin-bottom: 10px;
                    display: block;

                    label.form-label {
                        display: block;
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 5px;
                    }

                    .form-control {
                        width: 100%;
                        padding: 10px 8px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        box-sizing: border-box;
                        font-size: 18px;
                        color: $primary-text;
                    }
                }

                @include responsive(sm) {
                    .grid {
                        display: block;
                    }

                    .grid-33,
                    .grid-50 {
                        width: 100%;
                    }
                }
            }

            .summary {
                background: $background-paper;
                flex: 1;
                flex-direction: column;
                padding: 20px;
                position: -webkit-sticky;
                position: sticky;
                top: 0;

                .order {
                    display: flex;
                    flex-direction: row;

                    img {
                        width: 100%;
                        max-width: 90px;
                        margin-right: 10px;
                        ;
                    }

                    .details {
                        p {
                            padding: 8px;
                            font-size: 1rem;
                        }
                    }
                }

                .total {
                    border-bottom: 1px dotted #ccc;

                    h3 {
                        font-size: 1.3rem;
                        font-weight: 500;
                        margin: 0;
                        padding: 10px 0px;
                        text-align: right;
                        color: $primary-color;
                    }
                }

                .shipping {
                    border-bottom: 1px dotted #ccc;
                    padding: 30px 0px;

                    label.form-label {
                        width: 100%;
                        display: block;
                        padding: 8px;
                        font-size: 1rem;

                        input {
                            margin-right: 10px;
                        }
                    }
                }

                .btn-wrapper {
                    width: 100%;
                    margin-top: 30px;

                    .btn {
                        width: 100%;
                    }
                }
            }

            .consent {
                padding: 12px 0px;

                label {
                    padding: 8px;
                    display: block;

                    input {
                        margin-right: 10px;
                    }

                    a {
                        text-decoration: none;
                        color: $primary-color;
                    }
                }
            }
        }

        .questionnaire {
            padding: 30px;

            ol {
                margin: 0;
                padding: 0;
                margin-left: 20px;

                li {
                    margin-bottom: 20px;

                    .question {
                        .form-label {
                            font-size: 1rem;
                            text-align: left;
                            line-height: 26px;
                        }

                        .yesno {
                            display: inline-block;
                            font-size: 1rem;
                            padding: 10px;

                            input[type="radio"] {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .terms {
        margin-top: 130px;
        margin-bottom: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            margin-top: 50px;
            margin-bottom: 30px;
            font-size: 3rem;
            font-weight: 500;
        }

        .content {
            background: $background-paper;
            padding: 40px 30px;

            p {
                line-height: 26px;
                margin-bottom: 20px;
            }

            ul, ol {
                margin: 10px;
                padding: 0;

                li {
                    line-height: 26px;
                    font-size: 1.1rem;
                    margin-bottom: 8px;
                }
            }

            ul {
                list-style: disc;
            }
        }
    }

    .pusher {
        width: 100%;
        height: 250px;
    }
}

.footer {
    background: $primary-text;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    .col {
        flex: 1;

        &.logo {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            img {
                width: 100%;
                max-width: 170px;
            }

            span {
                color: $background-paper;
                margin-left: 10px;
            }
        }

        &.contact-us {
            flex: 2;
            display: flex;
            align-items: flex-start;
            justify-content: right;

            & .links {
                color: $background-paper;
                padding: 8px 30px;

                @include responsive(sm) {
                    display: none;
                }

                h3 {
                    padding: 8px 0px;
                    border-bottom: 1px dotted #fff;
                    margin-bottom: 10px;
                }

                a {
                    display: block;
                    color: $background-paper;
                    padding: 5px 0px;
                    text-decoration: none;
                    font-size: 15px;
                }
            }

            & .contact {
                color: $background-paper;
                padding: 8px 30px;

                h3 {
                    padding: 8px 0px;
                    border-bottom: 1px dotted #fff;
                    margin-bottom: 10px;
                }

                p {
                    padding: 3px 0px;
                    line-height: 23px;
                    display: flex;
                    align-items: center;
                    font-size: 15px;

                    .material-symbols-outlined {
                        font-size: 20px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}